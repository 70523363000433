// Libraries
import React, {PureComponent} from 'react';

import base from '../base';

class FiltrosCabecera extends PureComponent {
    
    constructor(){
        super();

		this._focusDropdown = this._focusDropdown.bind(this);
		this._closeAllDropdowns = this._closeAllDropdowns.bind(this);
		this._renderCentros = this._renderCentros.bind(this);
		this._setSelectedCentro = this._setSelectedCentro.bind(this);
		this._searchQuery = this._searchQuery.bind(this);
		this._searchQueryEnter = this._searchQueryEnter.bind(this);

        this.state = {
			medico: '',
			buscarTxt: '',
			selectedCentro: '',
			selectedMedico: ''
        };
    }

	componentDidMount(){
		const self = this;
		document.addEventListener('click', (event) => {			
			if (!event.target.closest('.btn-dropdown')){
				self._closeAllDropdowns();
			}
		}, false);

		base.database().ref('centros').once('value')
            .then((centros) => {
                if(centros.val() !== null){
					const centrosArr = centros.val();
					let nombresCentros = [];
					
					for (const centro in centrosArr) {
						if (centrosArr.hasOwnProperty(centro)) {
							nombresCentros.push(centrosArr[centro].nombre.replace(/_/g, ' '));
						}
					}

					self._renderCentros(nombresCentros);
				}
            })
            .catch((error)=>{
                console.log(error);
            });

		base.database().ref('usuarios-moviles').once('value')
            .then((medicos) => {
                if(medicos.val() !== null){
					const medicosArr = medicos.val();
					let nombresMedicos = [];
					
					for (const usuarioMedico in medicosArr) {
						if (medicosArr.hasOwnProperty(usuarioMedico)) {
							nombresMedicos.push(medicosArr[usuarioMedico].nombre + ' ' + medicosArr[usuarioMedico].apellido);
						}
					}

					self._renderMedicos(nombresMedicos);
				}
            })
            .catch((error)=>{
                console.log(error);
            });
	}

	_renderCentros(centros){
		let optionsCentros =  centros ? centros.map((centro, index) => <span key={'option-centro-'+index} className="option" onClick={()=>this._setSelectedCentro(centro)}>{centro}</span>) : null;
		this.setState({
			optionsCentros: optionsCentros
		})
	}

	_renderMedicos(medicos){
		let optionsMedicos =  medicos ? medicos.map((medico, index) => <span key={'option-medico-'+index} className="option" onClick={()=>this._setSelectedMedico(medico)}>{medico}</span>) : null;
		this.setState({
			optionsMedicos: optionsMedicos
		})
	}

	_setSelectedCentro(centro){
		const prevSelectedCentro = this.state.selectedCentro;

		if(prevSelectedCentro !== centro){
			this.setState({
				selectedCentro: centro
			});

			const filterObj = {
				query: this.buscarTxt.value,
				centro: centro,
				medico: this.state.selectedMedico
			}

			this.props.getCasosWithFilter(filterObj);
		}
	}

	_setSelectedMedico(medico){
		const prevSelectedMedico = this.state.selectedMedico;

		if(prevSelectedMedico !== medico){
			this.setState({
				selectedMedico: medico
			});

			const filterObj = {
				query: this.buscarTxt.value,
				centro: this.state.selectedCentro,
				medico: medico
			}

			this.props.getCasosWithFilter(filterObj);
		}
	}

	_closeAllDropdowns(){
		let drops = document.querySelectorAll('.options-group');
		drops.forEach(drop => {
			drop.classList.remove('show');
		});
	}

	_focusDropdown(id){
		this._closeAllDropdowns();
		
		let dropdown = document.getElementById(id);

		if(dropdown.className.indexOf('show') === -1){
			dropdown.classList.add('show');
		} else {
			dropdown.classList.remove('show');
		}
	}

	_searchQueryEnter(e){
		if(e.keyCode === 13){
            this._searchQuery();
        } else {
			if(this.buscarTxt.value.length === 0){
				const filterObj = {
					query: '',
					centro: this.state.selectedCentro,
					medico: this.state.selectedMedico
				}
				this.props.getCasosWithFilter(filterObj);
			}
		}
	}

	_searchQuery(){
		console.log('SearchQuery');
		if(this.buscarTxt.value.length){
			const filterObj = {
				query: this.buscarTxt.value,
				centro: this.state.selectedCentro,
				medico: this.state.selectedMedico
			}
			this.props.getCasosWithFilter(filterObj);
		}
	}

    render() {
        return ( 
            <div className="filtros">
				<span className="campo">
					<input name="buscarTxt" type="text" ref={(input) => this.buscarTxt = input} placeholder="Buscar" onKeyUp={this._searchQueryEnter}/>
					<span className="btn-buscar">
						<img src="/img/ico_buscar.png" alt="buscar" onClick={this._searchQuery} />
					</span>
				</span>

				<span className="campo">
					<div id="dropCentros" className="options-group">
						<span className="option-selected">
							{this.state.selectedCentro.length ? this.state.selectedCentro : 'Todos los centros'}
						</span>
						<div className="options-list">
							<span className="option" onClick={()=>this._setSelectedCentro('')}>Todos los centros</span>
							{this.state.optionsCentros}
						</div>
					</div>
					<span className="btn-dropdown" onClick={()=>this._focusDropdown('dropCentros')}>
						<img src="/img/ico_dropdown.png" alt="dropdown"/>
					</span>
				</span>

				<span className="campo">
					<div id="dropMedicos" className="options-group">
						<span className="option-selected">
							{this.state.selectedMedico.length ? this.state.selectedMedico : 'Todos los médicos'}
						</span>
						<div className="options-list">
							<span className="option" onClick={()=>this._setSelectedMedico('')}>Todos los médicos</span>
							{this.state.optionsMedicos}
						</div>
					</div>
					<span className="btn-dropdown" onClick={()=>this._focusDropdown('dropMedicos')}>
						<img src="/img/ico_dropdown.png" alt="dropdown"/>
					</span>
				</span>
				
			</div>
        )
    }
}

export default FiltrosCabecera;