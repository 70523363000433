// Libraries
import React from "react";

import Loading from "../Loading";
import Cabecera from "../Cabecera";

import base from "../../base";

import { mainPass, getUsuarioCentro } from "../../helpers";

import encrypter from "object-encrypter";
const encrypterEngine = encrypter(mainPass);

class Centros extends React.Component {
  constructor() {
    super();

    this._guardarCentro = this._guardarCentro.bind(this);

    this.state = {
      centro: "",
      loading: true,
      errorMessage: ""
    };
  }

  componentDidMount() {
    let usuario = getUsuarioCentro();

    if (usuario) {
      try {
        let usuarioCentro = encrypterEngine.decrypt(usuario);

        this.setState({
          usuario: usuarioCentro
        });

        if (
          usuarioCentro.centro !== undefined &&
          usuarioCentro.centro.length > 0
        ) {
          let self = this;

          base
            .database()
            .ref("centros/" + usuarioCentro.centro)
            .once("value")
            .then(value => {
              if (value.val() !== null) {
                let centro = value.val();
                self.setState({
                  centro: usuarioCentro.centro,
                  passwordUsuarios: centro.passwordUsuarios,
                  loading: false
                });
              }
            })
            .catch(error => {
              alert(error);
              self.setState({ loading: false });
              console.error(error);
            });
        } else {
          this.setState({ loading: false });
        }
      } catch (error) {
        window.localStorage.removeItem("usuarioCentro");
        window.location.href = "/error";
      }
    } else {
      window.location.href = "/";
    }
  }

  _guardarCentro() {
    if (
      !this.nombre.value.length ||
      !this.apellido.value.length ||
      !this.centro.value.length ||
      !this.localidad.value.length
    ) {
      this.setState({
        errorMessage: "Debe completar todos los campos"
      });
    } else {
      this.setState({ loading: true });

      let nombreAdmin = this.nombre.value.trim().toLowerCase();
      let apellidoAdmin = this.apellido.value.trim().toLowerCase();
      let localidad = this.localidad.value;
      let nombreCentro = this.centro.value
        .trim()
        .replace(/\s/g, "_")
        .toLowerCase();
      let self = this;

      base
        .database()
        .ref("centros/" + nombreCentro)
        .once("value")
        .then(value => {
          // Valida que exista el centro
          if (value.val() === null) {
            // Si no existe el centro se guarda

            // Password generado asociado a los usuarios del centro
            // Se aplicara a todos los usuarios que sean ingresados para este centro
            let password = Math.random()
              .toString(36)
              .substring(7);

            self.setState({
              passwordUsuarios: password
            });

            let d = new Date();
            let centro = {
              nombre: nombreCentro,
              creadoPor: self.state.usuario.uid,
              fechaCreacion: d.toString(),
              passwordUsuarios: password,
              localidad: localidad
            };

            let usuarioCentro = self.state.usuario;
            usuarioCentro.centro = nombreCentro;
            usuarioCentro.nombre = nombreAdmin;
            usuarioCentro.apellido = apellidoAdmin;

            base
              .database()
              .ref("centros/" + nombreCentro)
              .set(centro, error => {
                if (!error) {
                  base
                    .database()
                    .ref("usuarios-centros/" + usuarioCentro.uid)
                    .update(usuarioCentro, error => {
                      if (!error) {
                        self.setState({
                          centro: usuarioCentro.centro,
                          usuario: usuarioCentro,
                          loading: false
                        });
                        window.localStorage.setItem(
                          "usuarioCentro",
                          encrypterEngine.encrypt(usuarioCentro)
                        );
                      }
                    })
                    .catch(error => {
                      self.setState({ loading: false, errorMessage: error });
                    });
                }
              });
          } else {
            self.setState({
              errorMessage: "Ya existe un centro con ese nombre",
              loading: false
            });
          }
        })
        .catch(error => {
          alert(error);
          self.setState({ loading: false });
          console.error(error);
        });
    }
  }

  render() {
    return (
      <div
        className={
          "global-container " +
          (this.state.centro.length > 0 ? "" : "no-header")
        }
      >
        {this.state.centro.length > 0 ? <Cabecera admin /> : null}

        {this.state.loading ? (
          <Loading />
        ) : this.state.centro.length > 0 ? (
          <div className="info-usuario">
            <h2>Datos administrador centro</h2>
            <h3>
              Nombre:{" "}
              {this.state.usuario.nombre + " " + this.state.usuario.apellido}
            </h3>
            <p>
              Su usuario está asociado a{" "}
              <b>{this.state.centro.replace(/_/g, " ")}</b>
            </p>
            <p>
              Password generado para el centro de salud:{" "}
              <b>{this.state.passwordUsuarios}</b>
            </p>
            <button
              onClick={() => {
                window.location.href = "/usuarios";
              }}
            >
              Ver usuarios asociados al centro de salud
            </button>
          </div>
        ) : (
          <div className="admin-form">
            <img src="/img/interderm_logo.svg" alt="Interderm" />

            <h2>Datos administrador centro</h2>
            <p>
              No hay datos asociados a su usuario. Por favor, complete el
              siguiente formulario revisando la información antes de guardarla.
            </p>

            <label>Nombre:</label>
            <input
              type="text"
              ref={input => (this.nombre = input)}
              placeholder="Nombre"
            />

            <label>Apellido:</label>
            <input
              type="text"
              ref={input => (this.apellido = input)}
              placeholder="Apellido"
            />

            <label>Centro al que pertenece:</label>
            <input
              type="text"
              ref={input => (this.centro = input)}
              placeholder="Nombre del centro"
            />

            <label>Localidad:</label>
            <input
              type="text"
              ref={input => (this.localidad = input)}
              placeholder="Localidad del centro"
            />

            <button onClick={this._guardarCentro}>Guardar</button>

            {this.state.errorMessage.length ? (
              <span className="error-message">{this.state.errorMessage}</span>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Centros;
