import * as firebase from "firebase";

let config = {
  apiKey: "AIzaSyBGQNJZUZGtBffX5xuoE03MJ6nwt32kZ_o",
  authDomain: "interdermspa.firebaseapp.com",
  databaseURL: "https://interdermspa.firebaseio.com",
  projectId: "interdermspa",
  storageBucket: "interdermspa.appspot.com",
  messagingSenderId: "986686149164"
};

const base = firebase.initializeApp(config);

export default base;
