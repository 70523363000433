// Libraries
import React from "react";

import base from "../base";

import Loading from "./Loading";

import { mainPass } from "../helpers";
import encrypter from "object-encrypter";
const encrypterEngine = encrypter(mainPass);

class Login extends React.Component {
  constructor() {
    super();

    this._btnIngresar = this._btnIngresar.bind(this);
    this._loginDermatologo = this._loginDermatologo.bind(this);
    this._handleEnter = this._handleEnter.bind(this);

    this.state = {
      usuario: "",
      usuarioPassword: "",
      showLoader: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.usuario.value = this.state.usuario;
    this.password.value = this.state.usuarioPassword;
  }

  _btnIngresar() {
    this.setState({
      showLoader: true,
      usuario: this.usuario.value,
      usuarioPassword: this.password.value
    });
    // Valida usuario con la autenticación por password de Firebase
    // El usuario debe ser ingresado desde la plataforma de Firebase
    base
      .auth()
      .signInWithEmailAndPassword(this.usuario.value, this.password.value)
      .then(userData => {
        // UID asignado por la API de Firebase
        let uidUsuario = userData.user.uid;

        let usuarioCentro;
        let d = new Date();

        // Busca al usuario entre los usuarios de los centros
        base
          .database()
          .ref("usuarios-centros/" + uidUsuario)
          .once("value")
          .then(value => {
            // Valida que exista el usuario
            if (value.val() === null) {
              usuarioCentro = {
                uid: uidUsuario,
                email: userData.user.email
              };

              // Si no existe el usuario
              base
                .database()
                .ref("usuarios-centros/" + uidUsuario)
                .set(usuarioCentro, error => {
                  if (!error) {
                    usuarioCentro.fechaCreacion = d.toString();
                    usuarioCentro.ultimoAcceso = d.toString();

                    // Se guardará acceso a la app para mantener un log
                    base
                      .database()
                      .ref("accesos/" + uidUsuario)
                      .set(usuarioCentro, error => {
                        if (!error) {
                          window.localStorage.setItem(
                            "usuarioCentro",
                            encrypterEngine.encrypt(usuarioCentro)
                          );
                          window.location.href = "/centros";
                        }
                      });
                  } else {
                    this.setState({
                      showLoader: false,
                      errorMessage: error
                    });
                  }
                });
            } else {
              usuarioCentro = value.val();
              usuarioCentro.ultimoAcceso = d.toString();

              // Se guardará acceso a la app para mantener un log
              base
                .database()
                .ref("accesos/" + uidUsuario)
                .set(usuarioCentro, error => {
                  if (!error) {
                    window.localStorage.setItem(
                      "usuarioCentro",
                      encrypterEngine.encrypt(usuarioCentro)
                    );
                    if (
                      usuarioCentro.email !== "admin@interderm.cl" &&
                      (usuarioCentro.centro === undefined ||
                        usuarioCentro.centro.length === 0)
                    ) {
                      window.location.href = "/centros";
                    } else {
                      if (usuarioCentro.email === "admin@interderm.cl") {
                        window.location.href = "/dermatologos";
                      } else {
                        window.location.href = "/usuarios";
                      }
                    }
                  } else {
                    this.setState({
                      showLoader: false,
                      errorMessage: error
                    });
                  }
                });
            }
          })
          .catch(error => {
            this.setState({
              showLoader: false,
              errorMessage: error.message
            });
          });
      })
      .catch(() => {
        // Si hay un error con el login por email busca entre los dermatologos
        this._loginDermatologo();
      });
  }

  _loginDermatologo() {
    const usuario = this.state.usuario;
    const password = this.state.usuarioPassword;

    // Valida que venga el nombre de usuario y password
    if (usuario && password) {
      // Busca el nombre de usuario en la BD
      base
        .database()
        .ref("dermatologos/" + usuario)
        .once("value")
        .then(value => {
          // Si encuentra al usuario
          if (value.val() !== null) {
            let dermatologo = value.val();

            // Valida el password ingresado
            if (dermatologo.password === password) {
              let d = new Date();

              dermatologo = value.val();
              dermatologo.ultimoAcceso = d.toString();

              // Se guardará acceso a la app para mantener un log
              base
                .database()
                .ref("accesos/" + usuario)
                .set(dermatologo, error => {
                  if (!error) {
                    window.localStorage.setItem(
                      "usuarioDermatologo",
                      encrypterEngine.encrypt(dermatologo)
                    );
                    window.location.href = "/listado-casos";
                  } else {
                    this.setState({
                      showLoader: false,
                      errorMessage: error
                    });
                  }
                });
            } else {
              this.setState({
                showLoader: false,
                errorMessage: "Nombre de usuario o password incorrecto"
              });
            }
          } else {
            this.setState({
              showLoader: false,
              errorMessage: "Nombre de usuario o password incorrecto"
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      this.setState({
        showLoader: false,
        errorMessage: "Debe ingresar las credenciales"
      });
    }
  }

  _handleEnter(e) {
    if (e.keyCode === 13) {
      this._btnIngresar();
    }
  }

  render() {
    return (
      <div className="global-container login">
        {this.state.showLoader ? (
          <Loading />
        ) : (
          <div className="login-container">
            <div className="login">
              <img src="/img/interderm_logo.svg" alt="Interderm" />

              <label htmlFor="usuario">Nombre usuario:</label>
              <input
                id="usuario"
                name="usuario"
                type="text"
                ref={input => (this.usuario = input)}
                placeholder="nombre de usuario o e-mail"
              />

              <label htmlFor="passwordUsuario">Password:</label>
              <input
                id="passwordUsuario"
                name="passwordUsuario"
                type="password"
                ref={input => (this.password = input)}
                placeholder="password"
                onKeyUp={this._handleEnter}
              />

              <button onClick={this._btnIngresar}>Ingresar</button>
              {this.state.errorMessage.length ? (
                <span className="error-message">{this.state.errorMessage}</span>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Login;
