import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./css/style.css";

// Inicio
// import Home from './components/Home';
import Login from "./components/Login";

// Administracion Dermatologos
import AdminDermatologos from "./components/AdminDermatologos";

// Admin de Centro
import Usuarios from "./components/admin_centro/Usuarios";
import Centros from "./components/admin_centro/Centros";

// Dermatólogos
import ListadoCasos from "./components/ListadoCasos";

// 404 - 500
import NotFound from "./components/NotFound";
import ErrorPage from "./components/ErrorPage";

const Root = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/usuarios" component={Usuarios} />
        <Route exact path="/dermatologos" component={AdminDermatologos} />
        <Route exact path="/centros" component={Centros} />
        <Route exact path="/listado-casos" component={ListadoCasos} />
        <Route exact path="/error" component={ErrorPage} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

render(<Root />, document.querySelector("#main"));
