// Libraries
import React from "react";

import base from "../../base";

import { mainPass, getUsuarioCentro } from "../../helpers";

import Loading from "../Loading";
import Cabecera from "../Cabecera";

import encrypter from "object-encrypter";
const encrypterEngine = encrypter(mainPass);

class Usuarios extends React.Component {
  constructor() {
    super();

    this._guardarUsuario = this._guardarUsuario.bind(this);
    this._generateUsername = this._generateUsername.bind(this);
    this._getUsuariosAsociados = this._getUsuariosAsociados.bind(this);

    this.state = {
      centro: "",
      usuariosAsociados: [],
      usuariosAsociadosList: [],
      username: null,
      loading: false
    };
  }

  componentDidMount() {
    let usuario = getUsuarioCentro();

    // Valida que exista la información del usuario
    if (usuario) {
      let usuarioCentro = encrypterEngine.decrypt(usuario);

      this.setState({
        usuario: usuarioCentro
      });

      if (
        usuarioCentro.centro !== undefined &&
        usuarioCentro.centro.length > 0
      ) {
        this.setState({
          centro: usuarioCentro.centro.replace(/_/g, " "),
          loading: true
        });

        this._getUsuariosAsociados(usuarioCentro.centro);
      }
    } else {
      window.location.href = "/";
    }
  }

  _getUsuariosAsociados(centro) {
    let self = this;

    base
      .database()
      .ref("centros/" + centro)
      .once("value")
      .then(value => {
        // Valida que exista el centro
        if (value.val() !== null) {
          let centro = value.val();

          self.setState({
            passwordUsuarios: centro.passwordUsuarios
          });

          if (centro.usuariosAsociados !== undefined) {
            centro.usuariosAsociados.map((usuarioMovil, i) =>
              base
                .database()
                .ref("usuarios-moviles/" + usuarioMovil)
                .once("value")
                .then(usuario => {
                  if (usuario.val() !== null) {
                    let u = usuario.val();
                    const newUsuarioAsociado = (
                      <div className="usuario-asociado" key={i}>
                        <span className="dato-usuario">{usuarioMovil}</span>
                        <span className="dato-usuario">
                          {u.nombre + " " + u.apellido}
                        </span>
                        <span className="dato-usuario">{u.email}</span>
                        <span className="dato-usuario usuario-activo">
                          <input
                            type="checkbox"
                            checked={u.activo}
                            onChange={() => this._userActivation(usuarioMovil)}
                          />
                        </span>
                      </div>
                    );

                    self.setState({
                      loading: false,
                      usuariosAsociados: [
                        ...self.state.usuariosAsociados,
                        usuarioMovil
                      ],
                      usuariosAsociadosList: [
                        ...self.state.usuariosAsociadosList,
                        newUsuarioAsociado
                      ]
                    });
                  }
                })
            );
          } else {
            self.setState({ loading: false });
          }
        } else {
          self.setState({
            loading: false,
            errorMessage: "No existe el centro de salud"
          });
        }
      })
      .catch(error => {
        self.setState({
          loading: false,
          errorMessage: "Error al buscar el centro de salud"
        });
      });
  }

  _validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  _guardarUsuario() {
    const nombre = this.nombre.value.trim();
    const apellido = this.apellido.value.trim();
    const email = this.email.value.trim();
    const nombreUsuario = this.state.username;

    if (nombre.length && apellido.length && email.length) {
      if (this._validateEmail(email)) {
        this.setState({
          loading: true,
          errorMessage: null
        });

        let nuevosUsuarios = this.state.usuariosAsociados;

        if (this.state.usuariosAsociados.indexOf(nombreUsuario) === -1) {
          nuevosUsuarios.push(nombreUsuario);
          let centro = { usuariosAsociados: nuevosUsuarios };
          let self = this;

          // Actualiza el centro agregando el nombre de usuario
          base
            .database()
            .ref("centros/" + self.state.centro.replace(/\s/g, "_"))
            .update(centro, error => {
              if (!error) {
                base
                  .database()
                  .ref("usuarios-moviles/" + nombreUsuario)
                  .once("value")
                  .then(value => {
                    console.log(value.val());

                    // Valida que exista el usuario
                    if (value.val() === null) {
                      let usuario = {
                        activo: false,
                        centro: self.state.centro.replace(/\s/g, "_"),
                        nombre: nombre,
                        apellido: apellido,
                        email: email
                      };

                      base
                        .database()
                        .ref("usuarios-moviles/" + nombreUsuario)
                        .set(usuario, error => {
                          if (!error) {
                            window.location.reload();
                          }
                        });
                    } else {
                      this.setState({
                        errorMessage:
                          "El usuario " + nombreUsuario + " ya existe",
                        loading: false
                      });
                    }
                  })
                  .catch(error => {
                    this.setState({
                      errorMessage: error,
                      loading: false
                    });
                  });
              }
            });
        } else {
          this.setState({
            errorMessage: "El usuario " + nombreUsuario + " ya existe",
            loading: false
          });
        }
      } else {
        this.setState({
          errorMessage: "Email inválido"
        });
      }
    } else {
      this.setState({
        errorMessage: "Debe completar todos los campos"
      });
    }
  }

  _generateUsername() {
    const nombre = this.nombre.value
      .trim()
      .toLowerCase()
      .replace(/\s/g, "");
    const apellido = this.apellido.value
      .trim()
      .toLowerCase()
      .replace(/\s/g, "");
    const username = (nombre + "-" + apellido)
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    if (nombre.length && apellido.length) {
      this.setState({
        username: username
      });
    } else {
      this.setState({
        username: null
      });
    }
  }

  _userActivation(usuarioMovil) {
    const self = this;

    self.setState({
      loading: true
    });

    base
      .database()
      .ref("usuarios-moviles/" + usuarioMovil)
      .once("value")
      .then(value => {
        if (value.val() !== null) {
          let u = value.val();
          u.activo = !u.activo;
          base
            .database()
            .ref("usuarios-moviles/" + usuarioMovil)
            .update(u, error => {
              if (!error) {
                window.location.reload();
              }
            });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    return (
      <div className="global-container">
        <Cabecera admin />
        {this.state.loading ? (
          <Loading color="#009092" />
        ) : (
          <div className="usuarios-centro">
            <h2>
              Usuarios del centro <b>{this.state.centro}</b>
            </h2>
            <h3>
              Password para usuarios móviles:{" "}
              <b>{this.state.passwordUsuarios}</b>
            </h3>

            {this.state.usuariosAsociadosList.length !== 0 ? (
              <div className="usuario-asociado-lista">
                <div className="usuario-asociado titulos">
                  <span className="dato-usuario">Nombre usuario</span>
                  <span className="dato-usuario">Nombre y Apellido</span>
                  <span className="dato-usuario">Email</span>
                  <span className="dato-usuario">Activo</span>
                </div>
                {this.state.usuariosAsociadosList}
              </div>
            ) : (
              <p>
                <b>No existen usuarios asociados</b>
              </p>
            )}

            <div className="agregar-usuarios">
              <h3>Agregar usuarios</h3>

              <div className="campos">
                <label>Nombre</label>
                <input
                  type="text"
                  ref={input => (this.nombre = input)}
                  placeholder="Nombre"
                  onChange={this._generateUsername}
                />

                <label>Apellido</label>
                <input
                  type="text"
                  ref={input => (this.apellido = input)}
                  placeholder="Apellido"
                  onChange={this._generateUsername}
                />

                <label>E-mail</label>
                <input
                  type="text"
                  ref={input => (this.email = input)}
                  placeholder="correo@dominio.com"
                />
              </div>

              {this.state.username && this.state.username.length ? (
                <div className="username">
                  <h4>Nombre de usuario generado</h4>
                  <span>{this.state.username}</span>
                </div>
              ) : null}

              <button onClick={this._guardarUsuario}>Guardar</button>

              {this.state.errorMessage && this.state.errorMessage.length ? (
                <span className="error-message">{this.state.errorMessage}</span>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Usuarios;
