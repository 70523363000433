import React, { Component } from "react";

import { mainPass, getUsuarioDermatologo, getUsuarioCentro } from "../helpers";

import encrypter from "object-encrypter";
const encrypterEngine = encrypter(mainPass);

class UserInfo extends Component {
  constructor() {
    super();

    this._showLogout = this._showLogout.bind(this);

    this.state = {
      nombre: "",
      showLogout: false
    };
  }

  componentDidMount() {
    const dermatologo = getUsuarioDermatologo();
    let usuarioCentro = getUsuarioCentro();
    if (dermatologo) {
      this.setState({ nombre: encrypterEngine.decrypt(dermatologo).nombre });
    } else if (usuarioCentro) {
      usuarioCentro = encrypterEngine.decrypt(usuarioCentro);
      this.setState({
        nombre: usuarioCentro.nombre + " " + usuarioCentro.apellido
      });
    } else {
      window.location.href = "/login";
    }
  }

  _showLogout() {
    const showLogout = !this.state.showLogout;
    this.setState({ showLogout });
  }

  _logout() {
    window.localStorage.removeItem("usuarioDermatologo");
    window.localStorage.removeItem("usuarioCentro");
    window.location.href = "/login";
  }

  render() {
    return this.state.nombre ? (
      <div className="usuario" onClick={this._showLogout}>
        {!this.props.dermatologos ? <p>{this.state.nombre}</p> : null}
        <figure>
          <img src="/img/ico_usuario.svg" alt={this.state.nombre} />
        </figure>
        <div
          className={"logout " + (this.state.showLogout ? "show" : "")}
          onClick={this._logout}
        >
          <span>cerrar sesión</span>
        </div>
      </div>
    ) : null;
  }
}

export default UserInfo;
