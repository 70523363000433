import React, { PureComponent } from "react";
import moment from "moment";

import base from "../base";

import { getMes } from "../helpers";

class Caso extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      imgUrl: "",
      error: false
    };
  }

  componentDidMount() {
    const storageRef = base.storage().ref();

    const { datosCaso } = this.props;

    let img = storageRef.child(
      "pacientes/" +
        datosCaso.idArchivo +
        "/compressed/" +
        datosCaso.idArchivo +
        "_1.jpg"
    );

    img
      .getDownloadURL()
      .then(url => {
        this.setState({ imgUrl: url });
      })
      .catch(error => {
        console.error(error.code);
        this.setState({ error: true });
      });
  }

  render() {
    const {
      nombrePaciente,
      apellidoPaciente,
      datosUsuario,
      rutPaciente,
      historial
    } = this.props.datosCaso;

    let fechaUltimoHito = historial
      ? moment(historial[historial.length - 1].fecha).format("D/M/YYYY")
      : null;

    let arrFecha = [];
    if (fechaUltimoHito) {
      arrFecha = fechaUltimoHito ? fechaUltimoHito.split("/") : [];
    }

    return (
      <div
        className={"caso " + (this.state.error ? "error" : "")}
        onClick={() => {
          this.props.togglePreview(this.state.imgUrl, this.props.datosCaso);
        }}
      >
        <figure>
          {this.state.imgUrl.length ? (
            <img src={this.state.imgUrl} alt={rutPaciente} />
          ) : this.state.error ? (
            <span className="image-placeholder error">
              imagen no disponible
            </span>
          ) : (
            <span className="image-placeholder">∙∙∙</span>
          )}
        </figure>
        <div className="info">
          <div className="info-paciente">
            <h3 className="nombre">
              {nombrePaciente + " " + apellidoPaciente}
            </h3>
            <h4>{rutPaciente}</h4>
          </div>
          <div className="info-doctor">
            <p className="nombre-doctor">
              {datosUsuario.nombre + " " + datosUsuario.apellido}
            </p>
            <div className="nombre-centro">
              {datosUsuario.centro.replace(/_/g, " ")}
            </div>
          </div>
          <div className="fecha">
            <span className="mes">{getMes(arrFecha[1])}</span>
            <span className="dia">{arrFecha[0]}</span>
            <span className="anio">{arrFecha[2]}</span>
            <span className="punta" />
          </div>
        </div>
      </div>
    );
  }
}

export default Caso;
