import React, { PureComponent, Fragment } from "react";
import Loading from "./Loading";
import base from "../base";

class ArchivoPaciente extends PureComponent {
  constructor() {
    super();

    this._zoomImage = this._zoomImage.bind(this);

    this.state = {
      img1Url: null,
      img2Url: null,
      img3Url: null,
      img4Url: null
    };
  }

  componentDidMount() {
    const storageRef = base.storage().ref();

    const { idArchivo } = this.props.archivo;

    let img1Ref = storageRef.child(
      "pacientes/" + idArchivo + "/compressed/" + idArchivo + "_1.jpg"
    );
    let img2Ref = storageRef.child(
      "pacientes/" + idArchivo + "/compressed/" + idArchivo + "_2.jpg"
    );
    let img3Ref = storageRef.child(
      "pacientes/" + idArchivo + "/compressed/" + idArchivo + "_3.jpg"
    );
    let img4Ref = storageRef.child(
      "pacientes/" + idArchivo + "/compressed/" + idArchivo + "_4.jpg"
    );

    img1Ref.getDownloadURL().then(url => {
      this.setState({ img1Url: url });
    });
    img2Ref.getDownloadURL().then(url => {
      this.setState({ img2Url: url });
    });
    img3Ref.getDownloadURL().then(url => {
      this.setState({ img3Url: url });
    });
    img4Ref.getDownloadURL().then(url => {
      this.setState({ img4Url: url });
    });
  }

  _zoomImage(imgNumber = 0) {
    if (imgNumber > 0) {
      const storageRef = base.storage().ref();

      const { idArchivo } = this.props.archivo;

      let imgRef = storageRef.child(
        "pacientes/" + idArchivo + "/" + idArchivo + "_" + imgNumber + ".jpg"
      );

      this.setState({ loadingZoom: true });

      imgRef.getDownloadURL().then(url => {
        this.setState({
          imgZoomDetail: url,
          loadingZoom: false
        });
      });
    } else {
      this.setState({
        imgZoomDetail: null
      });
    }
  }

  render() {
    const {
      idArchivo,
      fechaLesion,
      descripcion,
      localizacion,
      infoAdicional,
      sintomas
    } = this.props.archivo;
    return (
      <div className="archivo-paciente-modal">
        <span
          className="close-archivo-paciente-modal"
          onClick={() => this.props.toggleArchivo()}
        >
          +
        </span>
        {this.state.imgZoomDetail && !this.state.loadingZoom ? (
          <div className="zoom-detail-archivo">
            <figure onClick={() => this._zoomImage()}>
              <img src={this.state.imgZoomDetail} alt={"zoom-" + idArchivo} />
            </figure>
          </div>
        ) : !this.state.imgZoomDetail && this.state.loadingZoom ? (
          <Loading color="#009092" />
        ) : (
          <Fragment>
            <p>
              <span>Fecha Lesión</span> {fechaLesion}
            </p>
            <p>
              <span>Descripción</span> {descripcion}
            </p>
            <p>
              <span>Localización</span> {localizacion}
            </p>
            <p>
              <span>Información Adicional</span> {infoAdicional}
            </p>

            <p>
              <span>Síntomas</span>
              <div className="sintomas-archivo">
                {this.props.renderSintomas(sintomas)}
              </div>
            </p>

            <div className="caso-images-container">
              {this.state.img1Url &&
              this.state.img2Url &&
              this.state.img3Url &&
              this.state.img4Url ? (
                <div className="caso-images">
                  <figure
                    onClick={
                      this.state.img1Url
                        ? () => {
                            this._zoomImage("1");
                          }
                        : null
                    }
                  >
                    <span className="zoom" />
                    {this.state.img1Url ? (
                      <img src={this.state.img1Url} alt={idArchivo} />
                    ) : (
                      "∙∙∙"
                    )}
                  </figure>
                  <figure
                    onClick={
                      this.state.img2Url
                        ? () => {
                            this._zoomImage("2");
                          }
                        : null
                    }
                  >
                    <span className="zoom" />
                    {this.state.img2Url ? (
                      <img src={this.state.img2Url} alt={idArchivo} />
                    ) : (
                      "∙∙∙"
                    )}
                  </figure>
                  <figure
                    onClick={
                      this.state.img3Url
                        ? () => {
                            this._zoomImage("3");
                          }
                        : null
                    }
                  >
                    <span className="zoom" />
                    {this.state.img3Url ? (
                      <img src={this.state.img3Url} alt={idArchivo} />
                    ) : (
                      "∙∙∙"
                    )}
                  </figure>
                  <figure
                    onClick={
                      this.state.img4Url
                        ? () => {
                            this._zoomImage("4");
                          }
                        : null
                    }
                  >
                    <span className="zoom" />
                    {this.state.img4Url ? (
                      <img src={this.state.img4Url} alt={idArchivo} />
                    ) : (
                      "∙∙∙"
                    )}
                  </figure>
                </div>
              ) : (
                <Loading color="#eeeeee" />
              )}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default ArchivoPaciente;
