// Libraries
import React, { PureComponent } from "react";

import FiltrosCabecera from "./FiltrosCabecera";
import UserInfo from "./UserInfo";

class Cabecera extends PureComponent {
  render() {
    return (
      <header className={"cabecera " + (this.props.admin ? "admin" : "")}>
        <h1>
          <a href="/">
            <img src="/img/interderm_logo_blanco.svg" alt="Interderm" />
          </a>
        </h1>

        {!this.props.admin ? (
          <FiltrosCabecera getCasosWithFilter={this.props.getCasosWithFilter} />
        ) : null}

        <UserInfo dermatologos={this.props.dermatologos} />
      </header>
    );
  }
}

export default Cabecera;
