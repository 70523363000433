import React from 'react';

class ErroPage extends React.Component {

    render () {
        return (
            <div id="content">
                <h1>Error </h1>
            </div>
        )
    }
}

export default ErroPage;
