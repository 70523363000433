import React, { PureComponent, Fragment } from "react";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import moment from "moment";
import Parser from "html-react-parser";
import _ from "lodash";

import base from "../base";

import Loading from "./Loading";
import ArchivoPaciente from "./ArchivoPaciente";

class CasoDetail extends PureComponent {
  constructor(props) {
    super(props);

    this._sendMail = this._sendMail.bind(this);
    this._onOptionSelect = this._onOptionSelect.bind(this);
    this._renderHistorial = this._renderHistorial.bind(this);
    this._handleMessageChange = this._handleMessageChange.bind(this);
    this._getLocalidadCentro = this._getLocalidadCentro.bind(this);
    this._toggleArchivoPaciente = this._toggleArchivoPaciente.bind(this);

    this.state = {
      img1Url: null,
      img2Url: null,
      img3Url: null,
      img4Url: null,
      firmaDermatologo: null,
      emailDermatologo: null,
      imgZoomDetail: null,
      sendingMail: false,
      historial: props.datosCaso.historial || [],
      buttonText: "Enviar",
      hideTextbox: false,
      message: "",
      localidad: "",
      selectedOption: "mas-info",
      archivosPaciente: null,
      archivoPaciente: null
    };
  }

  componentDidMount() {
    const storageRef = base.storage().ref();

    this._getLocalidadCentro();

    const { idArchivo, rutPaciente } = this.props.datosCaso;
    const nombreUsuarioDermatologo = this.props.nombreUsuarioDermatologo;

    let img1Ref = storageRef.child(
      "pacientes/" + idArchivo + "/compressed/" + idArchivo + "_1.jpg"
    );
    let img2Ref = storageRef.child(
      "pacientes/" + idArchivo + "/compressed/" + idArchivo + "_2.jpg"
    );
    let img3Ref = storageRef.child(
      "pacientes/" + idArchivo + "/compressed/" + idArchivo + "_3.jpg"
    );
    let img4Ref = storageRef.child(
      "pacientes/" + idArchivo + "/compressed/" + idArchivo + "_4.jpg"
    );

    let firmaDermatologo = storageRef.child(
      "firmas/" +
        nombreUsuarioDermatologo +
        "/" +
        nombreUsuarioDermatologo +
        ".jpg"
    );

    img1Ref.getDownloadURL().then(url => {
      this.setState({ img1Url: url });
    });
    img2Ref.getDownloadURL().then(url => {
      this.setState({ img2Url: url });
    });
    img3Ref.getDownloadURL().then(url => {
      this.setState({ img3Url: url });
    });
    img4Ref.getDownloadURL().then(url => {
      this.setState({ img4Url: url });
    });

    firmaDermatologo.getDownloadURL().then(url => {
      this.setState({ firmaDermatologo: url });
    });

    if (this.props.datosCaso.historial) {
      const hitosArr = Object.values(this.props.datosCaso.historial);
      _.map(hitosArr, hito => {
        if (hito.tipo === "confirmacion") {
          this.setState({ hideTextbox: true });
        }
      });
    }

    const self = this;

    base
      .database()
      .ref("pacientes_archivados/" + rutPaciente)
      .once("value")
      .then(archivos => {
        if (archivos.val() !== null) {
          let archivosPaciente = archivos.val();
          self.setState({
            archivosPaciente: archivosPaciente
          });
        }
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      emailDermatologo: this.props.emailDermatologo
    });
  }

  _renderSintomas(arrSintomas) {
    const sintomas = arrSintomas.map((sintoma, index) => {
      return sintoma[1] ? (
        <span key={"sintoma-" + index} className="sintoma">
          {sintoma[0].replace(/_/g, " ")}
        </span>
      ) : null;
    });

    return sintomas;
  }

  _renderArchivosPaciente(archivosPaciente) {
    let arrArchivosPaciente = [];

    archivosPaciente.forEach(archivo => {
      arrArchivosPaciente.push(
        <div key={archivo.idArchivo} className="archivo-paciente">
          <span
            className="archivo-paciente-fecha"
            onClick={() => this._toggleArchivoPaciente(archivo)}
          >
            {moment(archivo.historial[0].fecha).format("DD/MM/YYYY")}
          </span>
        </div>
      );
    });

    return arrArchivosPaciente;
  }

  _toggleArchivoPaciente(archivo = null) {
    const archivoPaciente = this.state.archivoPaciente;

    if (!archivoPaciente) {
      this.setState({ archivoPaciente: archivo });
    } else {
      this.setState({ archivoPaciente: null });
    }
  }

  _zoomImage(imgNumber = 0) {
    if (imgNumber > 0) {
      const storageRef = base.storage().ref();

      const { idArchivo } = this.props.datosCaso;

      let imgRef = storageRef.child(
        "pacientes/" + idArchivo + "/" + idArchivo + "_" + imgNumber + ".jpg"
      );

      this.setState({ loadingZoom: true });

      imgRef.getDownloadURL().then(url => {
        this.setState({
          imgZoomDetail: url,
          loadingZoom: false
        });
      });
    } else {
      this.setState({
        imgZoomDetail: null
      });
    }
  }

  _sendMail(datosUsuario) {
    if (this.state.selectedOption && this.message.value.length) {
      const self = this;

      const {
        rutPaciente,
        nombrePaciente,
        apellidoPaciente,
        edad,
        sexo
      } = self.props.datosCaso;

      self.setState({
        sendingMail: true,
        savingMessage: this.state.selectedOption === "respuesta"
      });

      var myEmail = base.functions().httpsCallable("orientacionMail");

      const subject =
        self.state.selectedOption === "mas-info"
          ? "Solicitud de información adicional"
          : "Confirmación impresión diagnóstica";

      const messageText = self.message.value.trimLeft().trimRight();

      let textoID = "";

      if (self.state.selectedOption === "confirmacion") {
        textoID = `<div style="color: #404040">El examen practicado al paciente <b>${nombrePaciente +
          " " +
          apellidoPaciente}</b>, ha dado el siguiente resultado:</div>

        <br />`;
      }

      const messageHtml = `
      <h1>
      <img
        src="https://interdermspa.firebaseapp.com/img/interderm_logo.jpg"
        alt="Interderm"
        style="width: 200px"
        title="Interderm"
      />
    </h1>
    <table>
      <tr>
        <td style="padding-right: 10%;">
          <table>
            <tr>
              <td
                colspan="2"
                style="color: #009092; font-family: sans-serif; font-size:18; font-weight:bold"
              >
                INFORMACIÓN DEL PACIENTE
              </td>
            </tr>
            <tr>
              <td
                style="padding: 3px 0px;color: #009092; font-family: sans-serif"
              >
                Nombre
              </td>
              <td style="padding: 3px 0px;font-family: sans-serif">
              ${nombrePaciente + " " + apellidoPaciente}
              </td>
            </tr>
            <tr>
              <td
                style="padding: 3px 0px;color: #009092; font-family: sans-serif"
              >
                Edad
              </td>
              <td style="padding: 3px 0px;font-family: sans-serif">
              ${edad}
              </td>
            </tr>
            <tr>
              <td
                style="padding: 3px 0px;color: #009092; font-family: sans-serif"
              >
                Rut
              </td>
              <td style="padding: 3px 0px;font-family: sans-serif">
              ${rutPaciente}
              </td>
            </tr>
            <tr>
              <td
                style="padding: 3px 0px;color: #009092; font-family: sans-serif"
              >
                Sexo
              </td>
              <td style="padding: 3px 0px;font-family: sans-serif">
                ${sexo === "f" ? "femenino" : "masculino"}
              </td>
            </tr>
          </table>
        </td>
        <td style="vertical-align: top">
          <table>
            <tr>
              <td
                colspan="2"
                style="color: #009092; font-family: sans-serif; font-size:18; font-weight:bold"
              >
                INFORMACIÓN CENTRO MÉDICO
              </td>
            </tr>
            <tr>
              <td
                style="padding: 3px 0px; color: #009092; font-family: sans-serif"
              >
                Centro
              </td>
              <td style="padding: 3px 0px; font-family: sans-serif">
                ${datosUsuario.centro.replace(/_/g, " ")}
              </td>
            </tr>
            <tr>
              <td
                style="padding: 3px 0px; color: #009092; font-family: sans-serif"
              >
                Médico
              </td>
              <td style="padding: 3px 0px; font-family: sans-serif">
                ${datosUsuario.nombre} ${datosUsuario.apellido}
              </td>
            </tr>
            <tr>
              <td
                style="padding: 3px 0px; color: #009092; font-family: sans-serif"
              >
                Localidad
              </td>
              <td style="padding: 3px 0px; font-family: sans-serif">
                ${this.state.localidad}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

    <div
      style="width: 80%; border: 1px dashed lightgrey; margin: 20px 0px"
    ></div>
    <h2 style="color: #009092; font-family: sans-serif">
      ${subject}
    </h2>

    <br />

    ${textoID}

    <div style="color: #404040">${messageText.replace(/\r?\n/g, "<br />")}</div>

			<span style="margin-top: 30px; color: #009092; font-size: 14px; display: block" >Dermatólogo ${
        this.props.nombreDermatologo
      }</span>
      <br />
      <img style="display:block; width: 200px" src="${
        this.state.firmaDermatologo
      }" alt="${this.props.nombreDermatologo}"/>
      `;

      let historial = self.state.historial;
      let date = new Date();

      const mensaje = {
        tipo: self.state.selectedOption,
        texto: messageText.replace(/\r?\n/g, "<br />"),
        fecha: date.toString()
      };

      historial.push(mensaje);

      let casoModified = {
        ...self.props.datosCaso,
        historial: historial,
        estado: self.state.selectedOption !== "confirmacion" ? 2 : 3
      };

      base
        .database()
        .ref("pacientes/" + rutPaciente)
        .update(casoModified, error => {
          if (!error) {
            if (self.state.selectedOption !== "respuesta") {
              myEmail({
                email: datosUsuario.email,
                displayName: "Interderm",
                emailDermatologo: self.state.emailDermatologo,
                nombreDermatologo: this.props.nombreDermatologo,
                text: "Interderm - " + subject,
                subject: "Interderm - " + subject,
                message: messageHtml
              }).then(() => {
                self.setState({
                  sendingMail: false,
                  historial: historial,
                  hideTextbox: self.state.selectedOption === "confirmacion",
                  message: ""
                });
              });
            } else {
              self.setState({
                sendingMail: false,
                savingMessage: false,
                historial: historial
              });
            }
          } else {
            alert("Error al intentar guardar el mensaje");
          }
        });
    } else {
      alert("Debe escribir un mensaje y seleccionar una opción");
    }
  }

  _renderHistorial() {
    const historial = this.state.historial;

    return historial.map((hito, index) => {
      let tipoColor, tipoMensaje;

      switch (hito.tipo) {
        case "proceso":
          tipoColor = "blue";
          tipoMensaje = "En proceso";
          break;
        case "mas-info":
          tipoColor = "blue";
          tipoMensaje = "Solicitud de información adicional";
          break;
        case "cola":
          tipoMensaje = "Ingreso a sistema";
          tipoColor = "red";
          break;
        case "respuesta":
          tipoMensaje = "Respuesta médico general";
          tipoColor = "red";
          break;
        case "confirmacion":
          tipoMensaje = "Confirmación impresión diagnóstica";
          tipoColor = "green";
          break;
        default:
          break;
      }

      return (
        <div key={"mensaje-" + index} className="mensaje">
          <span className={"mensaje-tipo " + tipoColor}>{tipoMensaje}</span>
          <span className="mensaje-fecha">
            {moment(hito.fecha).format("DD/MM/YYYY HH:mm")}
          </span>
          <div className="mensaje-text">{Parser(hito.texto)}</div>
        </div>
      );
    });
  }

  _onOptionSelect(value) {
    if (value === "respuesta") {
      this.setState({
        selectedOption: value,
        buttonText: "Guardar",
        message: ""
      });
    } else {
      if (value === "confirmacion") {
        let confirmationMessage = `FOTOGRAFÍA CLÍNICA Y DERMATOSCÓPICA CON LUZ POLARIZADA:

I. D.:

PLAN:

        `;

        this.setState({
          message: confirmationMessage
        });
      } else {
        this.setState({
          message: ""
        });
      }

      this.setState({
        selectedOption: value,
        buttonText: "Enviar"
      });
    }
  }

  _handleMessageChange(message) {
    this.setState({ message });
  }

  _getLocalidadCentro() {
    base
      .database()
      .ref("centros/" + this.props.datosCaso.datosUsuario.centro)
      .once("value")
      .then(centroDic => {
        if (centroDic.val() !== null) {
          const centro = centroDic.val();
          this.setState({ localidad: centro.localidad });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const {
      nombrePaciente,
      apellidoPaciente,
      edad,
      rutPaciente,
      descripcion,
      fechaLesion,
      infoAdicional,
      localizacion,
      sintomas,
      sexo,
      datosUsuario
    } = this.props.datosCaso;

    const { nombre, apellido, centro, email } = datosUsuario;
    return (
      <Fragment>
        {this.state.archivoPaciente ? (
          <ArchivoPaciente
            archivo={this.state.archivoPaciente}
            toggleArchivo={this._toggleArchivoPaciente}
            renderSintomas={this._renderSintomas}
          />
        ) : null}

        <div
          className={
            "caso-detail-container " + (this.state.imgZoomDetail ? "zoom" : "")
          }
        >
          <div className="caso-detail">
            <header>
              <div
                className={
                  "caso-detail-back " +
                  (this.state.imgZoomDetail ? "close" : "")
                }
                onClick={
                  this.state.imgZoomDetail
                    ? () => this._zoomImage()
                    : () => this.props.toggleCasoDetail()
                }
              />
              <span>Ficha prediagnóstica</span>
            </header>

            {this.state.imgZoomDetail && !this.state.loadingZoom ? (
              <div className="zoom-detail">
                <figure>
                  <img
                    src={this.state.imgZoomDetail}
                    alt={"zoom-" + rutPaciente}
                  />
                </figure>
              </div>
            ) : !this.state.imgZoomDetail && this.state.loadingZoom ? (
              <Loading color="#009092" />
            ) : (
              <div className="caso-detail-content">
                <div className="info-caso">
                  <div className="row">
                    <div className="col">
                      <h2>Información personal</h2>
                      <div className="data">
                        <div className="key">Nombre</div>
                        <div className="value highlight">
                          {nombrePaciente + " " + apellidoPaciente}
                        </div>
                      </div>
                      <div className="data">
                        <div className="key">Edad</div>
                        <div className="value">{edad}</div>
                      </div>
                      <div className="data">
                        <div className="key">RUT</div>
                        <div className="value">{rutPaciente}</div>
                      </div>
                      <div className="data">
                        <div className="key">Sexo</div>
                        <div className="value">
                          {sexo === "m" ? "Masculino" : "Femenino"}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <h2>Información centro médico</h2>
                      <div className="data">
                        <div className="key">Nombre</div>
                        <div className="value highlight">
                          {centro.replace(/_/g, " ")}
                        </div>
                      </div>
                      <div className="data">
                        <div className="key">Médico</div>
                        <div className="value">{nombre + " " + apellido}</div>
                      </div>
                      <div className="data">
                        <div className="key">Email </div>
                        <div className="value">
                          <a href={"mailto:" + email}>{email}</a>
                        </div>
                      </div>
                      <div className="data">
                        <div className="key">Localidad </div>
                        <div className="value">
                          <div className="value">{this.state.localidad}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col full-width">
                      <h2>Descripción lesión</h2>
                      <div className="data">
                        <div className="key">Fecha Lesión</div>
                        <div className="value">{fechaLesion}</div>
                      </div>
                      <div className="data">
                        <div className="key">Descripción</div>
                        <div className="value">{descripcion}</div>
                      </div>
                      <div className="data">
                        <div className="key">Localización</div>
                        <div className="value">{localizacion}</div>
                      </div>
                      {infoAdicional.length ? (
                        <div className="data">
                          <div className="key">Información Adicional</div>
                          <div className="value">{infoAdicional}</div>
                        </div>
                      ) : null}
                      <div className="data">
                        <div className="key">Síntomas</div>
                        <div className="value">
                          {this._renderSintomas(sintomas)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.archivosPaciente ? (
                  <div className="archivos-paciente">
                    <h2>Archivos Paciente</h2>
                    {this._renderArchivosPaciente(this.state.archivosPaciente)}
                  </div>
                ) : null}

                <div className="caso-images-container">
                  {this.state.img1Url &&
                  this.state.img2Url &&
                  this.state.img3Url &&
                  this.state.img4Url ? (
                    <div className="caso-images">
                      <figure
                        onClick={
                          this.state.img1Url
                            ? () => {
                                this._zoomImage("1");
                              }
                            : null
                        }
                      >
                        <span className="zoom" />
                        {this.state.img1Url ? (
                          <img src={this.state.img1Url} alt={rutPaciente} />
                        ) : (
                          "∙∙∙"
                        )}
                      </figure>
                      <figure
                        onClick={
                          this.state.img2Url
                            ? () => {
                                this._zoomImage("2");
                              }
                            : null
                        }
                      >
                        <span className="zoom" />
                        {this.state.img2Url ? (
                          <img src={this.state.img2Url} alt={rutPaciente} />
                        ) : (
                          "∙∙∙"
                        )}
                      </figure>
                      <figure
                        onClick={
                          this.state.img3Url
                            ? () => {
                                this._zoomImage("3");
                              }
                            : null
                        }
                      >
                        <span className="zoom" />
                        {this.state.img3Url ? (
                          <img src={this.state.img3Url} alt={rutPaciente} />
                        ) : (
                          "∙∙∙"
                        )}
                      </figure>
                      <figure
                        onClick={
                          this.state.img4Url
                            ? () => {
                                this._zoomImage("4");
                              }
                            : null
                        }
                      >
                        <span className="zoom" />
                        {this.state.img4Url ? (
                          <img src={this.state.img4Url} alt={rutPaciente} />
                        ) : (
                          "∙∙∙"
                        )}
                      </figure>
                    </div>
                  ) : (
                    <Loading color="#eeeeee" />
                  )}
                </div>

                {this.state.historial.length ? (
                  <div className="historial-mensajes-container row">
                    <h2>Historial</h2>
                    {this._renderHistorial()}
                  </div>
                ) : null}

                {!this.state.hideTextbox ? (
                  <div className="caso-interaction-container row">
                    {this.state.sendingMail ? (
                      <div className="sending-mail">
                        <h3>
                          {this.state.savingMessage
                            ? "Guardando respuesta"
                            : "Enviando mensaje"}
                        </h3>
                        <Loading color="#009092" />
                      </div>
                    ) : (
                      <div className="caso-interaction">
                        <h2>Orientación Diagnóstica</h2>

                        <RadioGroup
                          onChange={this._onOptionSelect}
                          horizontal
                          className="caso-interaction-options"
                          value={this.state.selectedOption}
                        >
                          <RadioButton
                            value="mas-info"
                            rootColor="#45A2FF"
                            pointColor="#45A2FF"
                          >
                            Solicitar más información
                          </RadioButton>
                          <RadioButton
                            value="respuesta"
                            rootColor="#ff9092"
                            pointColor="#ff9092"
                          >
                            Respuesta médico general
                          </RadioButton>
                          <RadioButton
                            value="confirmacion"
                            rootColor="#00bd92"
                            pointColor="#00bd92"
                          >
                            Confirmar impresión diagnóstica
                          </RadioButton>
                        </RadioGroup>

                        <textarea
                          className="caso-textbox"
                          value={this.state.message}
                          ref={input => (this.message = input)}
                          onChange={() =>
                            this._handleMessageChange(this.message.value)
                          }
                        />

                        <button
                          onClick={() =>
                            this._sendMail(
                              datosUsuario,
                              rutPaciente,
                              nombrePaciente,
                              apellidoPaciente
                            )
                          }
                        >
                          {this.state.buttonText}
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CasoDetail;
