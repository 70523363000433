// Libraries
import React from "react";
import FileUploader from "react-firebase-file-uploader";

import base from "../base";

import { mainPass, getUsuarioCentro } from "../helpers";

import Loading from "./Loading";
import Cabecera from "./Cabecera";

import encrypter from "object-encrypter";
const encrypterEngine = encrypter(mainPass);

class AdminDermatologos extends React.Component {
  constructor() {
    super();

    this._generateUsername = this._generateUsername.bind(this);
    this._getFirmasDermatologos = this._getFirmasDermatologos.bind(this);
    this._getDermatologos = this._getDermatologos.bind(this);
    this._guardarDermatologo = this._guardarDermatologo.bind(this);
    this._deleteDermatologo = this._deleteDermatologo.bind(this);

    this.state = {
      dermatologos: [],
      username: null,
      loading: false,
      firmas: {}
    };
  }

  componentDidMount() {
    let usuario = getUsuarioCentro();

    // Valida que exista la información del usuario
    if (usuario) {
      let usuarioCentro = encrypterEngine.decrypt(usuario);

      this.setState({
        usuario: usuarioCentro
      });

      this._getFirmasDermatologos();
    } else {
      window.location.href = "/";
    }
  }

  _getFirmasDermatologos() {
    let self = this;
    self.setState({
      loading: true
    });

    base
      .database()
      .ref("dermatologos")
      .once("value")
      .then(value => {
        if (value.val() !== null) {
          let dermatologos = value.val();

          for (const key in dermatologos) {
            if (dermatologos.hasOwnProperty(key)) {
              const dermatologo = dermatologos[key];

              base
                .storage()
                .ref("firmas/" + dermatologo.nombreUsuario)
                .child(dermatologo.nombreUsuario + ".jpg")
                .getDownloadURL()
                .then(url => {
                  if (url && url.length) {
                    self.setState({
                      firmas: {
                        ...self.state.firmas,
                        [dermatologo.nombreUsuario]: url
                      }
                    });
                  } else {
                    self.setState({
                      firmas: {
                        ...self.state.firmas,
                        [dermatologo.nombreUsuario]: false
                      }
                    });
                  }
                });
            }
          }

          setTimeout(() => {
            self._getDermatologos();
          }, 2000);
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(() => {
        self.setState({
          loading: false,
          errorMessage: "Error al buscar el centro de salud"
        });
      });
  }

  _getDermatologos() {
    let self = this;

    self.setState({
      dermatologos: []
    });

    base
      .database()
      .ref("dermatologos")
      .once("value")
      .then(value => {
        // Valida que exista el centro
        if (value.val() !== null) {
          let dermatologos = value.val();

          let counter = 0;
          for (const key in dermatologos) {
            if (dermatologos.hasOwnProperty(key)) {
              const dermatologo = dermatologos[key];

              const newDermatologo = (
                <div className="usuario-asociado" key={counter}>
                  <span className="dato-usuario">{dermatologo.nombre}</span>
                  <span className="dato-usuario">
                    {dermatologo.nombreUsuario}
                  </span>
                  <span className="dato-usuario">{dermatologo.email}</span>
                  <div className="dato-usuario">
                    {self.state.firmas[dermatologo.nombreUsuario] ? "Ok" : null}
                  </div>
                  <span className="dato-usuario">
                    <FileUploader
                      accept="image/jpeg"
                      name="firma"
                      filename={dermatologo.nombreUsuario}
                      storageRef={base
                        .storage()
                        .ref("firmas/" + dermatologo.nombreUsuario)}
                      onUploadStart={self.handleUploadStart}
                      onUploadError={self.handleUploadError}
                      onUploadSuccess={self.handleUploadSuccess}
                      onProgress={self.handleProgress}
                    />
                  </span>
                  <div className="datos-usuario">
                    <button
                      onClick={() =>
                        this._deleteDermatologo(dermatologo.nombreUsuario)
                      }
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              );

              self.setState({
                loading: false,
                dermatologos: [...self.state.dermatologos, newDermatologo]
              });
              counter++;
            }
          }
        }

        self.setState({ loading: false });
      })
      .catch(() => {
        self.setState({
          loading: false,
          errorMessage: "Error al buscar el centro de salud"
        });
      });
  }

  _generateUsername() {
    const nombre = this.nombre.value
      .trim()
      .toLowerCase()
      .replace(/\s/g, "");
    const apellido = this.apellido.value
      .trim()
      .toLowerCase()
      .replace(/\s/g, "");
    const username = (nombre + "-" + apellido)
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    if (nombre.length && apellido.length) {
      this.setState({
        username: username
      });
    } else {
      this.setState({
        username: null
      });
    }
  }

  _guardarDermatologo() {
    const nombre = this.nombre.value.trim().toLowerCase();
    const apellido = this.apellido.value.trim().toLowerCase();
    const password = this.password.value.trim().toLowerCase();
    const email = this.email.value.trim().toLowerCase();

    const dermatologo = {
      nombre: nombre + " " + apellido,
      nombreUsuario: this.state.username,
      email: email,
      password: password
    };

    const self = this;

    if (this.state.username && password) {
      base
        .database()
        .ref("dermatologos/" + this.state.username)
        .set(dermatologo, error => {
          if (!error) {
            window.location.reload();
          } else {
            self.setState({
              loading: false,
              errorMessage: "Error al buscar el centro de salud"
            });
          }
        });
    } else {
      self.setState({
        loading: false,
        errorMessage: "Debes completar los datos"
      });
    }
  }

  _deleteDermatologo(nombreUsuario) {
    const self = this;

    if (window.confirm("Desea eliminar al usuario " + nombreUsuario + "?")) {
      base
        .database()
        .ref("dermatologos/" + nombreUsuario)
        .set(null, error => {
          if (!error) {
            window.location.reload();
          } else {
            self.setState({
              loading: false,
              errorMessage: "Error al eliminar al dermatólogo"
            });
          }
        });
    }
  }

  handleUploadStart = () => this.setState({ loading: true });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ loading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    this.setState({ progress: 100, loading: false });
    base
      .storage()
      .ref("firmas/" + filename)
      .child(filename)
      .getDownloadURL()
      .then(() => {
        this._getFirmasDermatologos();
      })
      .catch(() => {
        this._getFirmasDermatologos();
      });
  };

  render() {
    return (
      <div className="global-container">
        <Cabecera admin dermatologos />
        {this.state.loading ? (
          <Loading color="#009092" />
        ) : (
          <div className="usuarios-centro dermatologos">
            <h2>Dermatólogos</h2>
            {this.state.dermatologos.length ? (
              <div className="usuario-asociado-lista dermatologos">
                <div className="usuario-asociado titulos">
                  <span className="dato-usuario">Nombre y Apellido</span>
                  <span className="dato-usuario">Nombre usuario</span>
                  <span className="dato-usuario">Email</span>
                  <span className="dato-usuario">Firma</span>
                  <span className="dato-usuario" />
                  <span className="dato-usuario" />
                </div>
                {this.state.dermatologos}
              </div>
            ) : (
              <p>
                <b>No existen dermatólogos</b>
              </p>
            )}

            <div className="agregar-usuarios">
              <h3>Agregar dermatólogo</h3>

              <div className="campos">
                <label>Nombre</label>
                <input
                  type="text"
                  ref={input => (this.nombre = input)}
                  placeholder="Nombre"
                  onChange={this._generateUsername}
                />

                <label>Apellido</label>
                <input
                  type="text"
                  ref={input => (this.apellido = input)}
                  placeholder="Apellido"
                  onChange={this._generateUsername}
                />

                <label>Email</label>
                <input
                  type="email"
                  ref={input => (this.email = input)}
                  placeholder="email"
                />

                <label>Password</label>
                <input
                  type="text"
                  ref={input => (this.password = input)}
                  placeholder="Password"
                  onChange={this._generateUsername}
                />
              </div>

              {this.state.username && this.state.username.length ? (
                <div className="username">
                  <h4>Nombre de usuario generado</h4>
                  <span>{this.state.username}</span>
                </div>
              ) : null}

              <button onClick={this._guardarDermatologo}>Guardar</button>

              {this.state.errorMessage && this.state.errorMessage.length ? (
                <span className="error-message">{this.state.errorMessage}</span>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AdminDermatologos;
