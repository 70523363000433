import React, { PureComponent } from "react";

import { getMes } from "../helpers";

class CasoPreview extends PureComponent {
  render() {
    const {
      imgUrl,
      nombrePaciente,
      nombreDoctor,
      rutPaciente,
      centro,
      fecha,
      caso
    } = this.props.preview;

    const arrFecha = fecha.split("/");

    return (
      <div className="caso-preview">
        <span className="close-preview" onClick={this.props.togglePreview}>
          +
        </span>

        <figure className="logo-interderm">
          <img src="/img/interderm_logo.svg" alt="Interderm" />
        </figure>

        <h2>¿Está seguro que desea tomar el caso?</h2>

        <div className="caso">
          <figure>
            <img src={imgUrl} alt={rutPaciente} />
          </figure>
          <div className="info">
            <div className="info-paciente">
              <h3 className="nombre">{nombrePaciente}</h3>
              <h4>{rutPaciente}</h4>
            </div>
            <div className="info-doctor">
              <p className="nombre-doctor">{nombreDoctor}</p>
              <div className="nombre-centro">{centro}</div>
            </div>
            <div className="fecha">
              <span className="mes">{getMes(arrFecha[1])}</span>
              <span className="dia">{arrFecha[0]}</span>
              <span className="anio">{arrFecha[2]}</span>
              <span className="punta" />
            </div>
          </div>
        </div>

        <button onClick={() => this.props.casoAssign(caso)}>
          Sí, tomar caso
        </button>
      </div>
    );
  }
}

export default CasoPreview;
