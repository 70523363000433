import React from 'react';

class NotFound extends React.Component {

    render () {
        return (
            <div id="content">
                <h1>Sorry, not Found</h1>
            </div>
        )
    }
}

export default NotFound;
