// Libraries
import React from "react";
import ScrollArea from "react-scrollbar";
import moment from "moment";
import _ from "lodash";

import Loading from "./Loading";
import Cabecera from "./Cabecera";
import Caso from "./Caso";
import CasoDetail from "./CasoDetail";
import CasoPreview from "./CasoPreview";

import base from "../base";

import { mainPass, getUsuarioDermatologo } from "../helpers";

import encrypter from "object-encrypter";
const encrypterEngine = encrypter(mainPass);

class ListadoCasos extends React.Component {
  constructor() {
    super();

    this._filtroCasos = this._filtroCasos.bind(this);
    this._getFromDb = this._getFromDb.bind(this);
    this._getCasos = this._getCasos.bind(this);
    this._getCasosWithFilter = this._getCasosWithFilter.bind(this);
    this._togglePreview = this._togglePreview.bind(this);
    this._toggleCasoDetail = this._toggleCasoDetail.bind(this);
    this._casoAssign = this._casoAssign.bind(this);
    this._renderCasosCola = this._renderCasosCola.bind(this);
    this._renderCasosProceso = this._renderCasosProceso.bind(this);
    this._renderCasosFinalizados = this._renderCasosFinalizados.bind(this);

    this.state = {
      loading: false,
      casos: [],
      casosCola: [],
      casosProceso: [],
      casosFinalizados: [],
      showDetail: false,
      preview: null,
      showPreview: false,
      datosCasoDetail: null,
      searchFilter: null
    };
  }

  componentDidMount() {
    const dermatologo = encrypterEngine.decrypt(getUsuarioDermatologo());
    this.setState({
      nombreDermatologo: dermatologo.nombre,
      nombreUsuarioDermatologo: dermatologo.nombreUsuario,
      emailDermatologo: dermatologo.email
    });
    this._getFromDb();
  }

  _getFromDb() {
    const self = this;
    self.setState({ loading: true });

    base
      .database()
      .ref("pacientes")
      .once("value")
      .then(casos => {
        if (casos.val() !== null) {
          this.setState({
            casosOriginal: Object.values(casos.val()),
            casos: Object.values(casos.val())
          });

          this._filtroCasos(Object.values(casos.val()));
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  _getCasosWithFilter(filtro = null) {
    if (filtro) {
      this.setState({ searchFilter: filtro });
    }
    this._toggleCasoDetail();
    this._getCasos();
  }

  _getCasos() {
    this._filtroCasos(this.state.casosOriginal);
  }

  _filtroDermatologo(arrCasos) {
    let filteredCasos = [];
    const dermatologo = encrypterEngine.decrypt(getUsuarioDermatologo())
      .nombreUsuario;

    arrCasos.forEach(caso => {
      if (caso.dermatologo) {
        if (caso.dermatologo === dermatologo) {
          filteredCasos.push(caso);
        }
      } else {
        filteredCasos.push(caso);
      }
    });

    return filteredCasos;
  }

  _filtroCasos(arrCasos) {
    let arrCasosCola = [],
      arrCasosProceso = [],
      arrCasosFinalizados = [];

    const filtro = this.state.searchFilter;

    arrCasos = this._filtroDermatologo(arrCasos);

    if (filtro) {
      if (arrCasos && arrCasos.length) {
        arrCasos = _.map(arrCasos, caso => {
          if (
            caso.datosUsuario.centro
              .replace(/_/g, " ")
              .toLowerCase()
              .indexOf(filtro.centro.toLowerCase()) > -1
          ) {
            return caso;
          }
        });
        arrCasos = _.without(arrCasos, undefined);
      }

      if (arrCasos && arrCasos.length) {
        arrCasos = _.map(arrCasos, caso => {
          const nombreDoctor =
            caso.datosUsuario.nombre + " " + caso.datosUsuario.apellido;
          if (
            nombreDoctor.toLowerCase().indexOf(filtro.medico.toLowerCase()) > -1
          ) {
            return caso;
          }
        });
        arrCasos = _.without(arrCasos, undefined);
      }

      if (arrCasos && arrCasos.length) {
        arrCasos = _.map(arrCasos, caso => {
          const nombrePaciente =
            caso.nombrePaciente + " " + caso.apellidoPaciente;
          if (
            nombrePaciente.toLowerCase().indexOf(filtro.query.toLowerCase()) >
              -1 ||
            caso.rutPaciente.toLowerCase().indexOf(filtro.query.toLowerCase()) >
              -1
          ) {
            return caso;
          }
        });
        arrCasos = _.without(arrCasos, undefined);
      }
    }

    for (let i = 0; i < arrCasos.length; i++) {
      if (!arrCasos[i].estado || arrCasos[i].estado === 1) {
        arrCasosCola.push(arrCasos[i]);
      } else if (arrCasos[i].estado === 2) {
        arrCasosProceso.push(arrCasos[i]);
      } else if (arrCasos[i].estado === 3) {
        arrCasosFinalizados.push(arrCasos[i]);
      }
    }

    this.setState({
      casos: arrCasos,
      casosCola: arrCasosCola,
      casosProceso: arrCasosProceso,
      casosFinalizados: arrCasosFinalizados,
      loading: false
    });
  }

  _renderCasosCola() {
    let casosCola = this.state.casosCola;
    if (casosCola.length) {
      return casosCola.map((caso, index) => (
        <Caso
          key={"caso-" + index}
          datosCaso={caso}
          togglePreview={this._togglePreview}
        />
      ));
    } else {
      return null;
    }
  }

  _renderCasosProceso() {
    let casosProceso = this.state.casosProceso;
    if (casosProceso.length) {
      return casosProceso.map((caso, index) => (
        <Caso
          key={"caso-" + index}
          datosCaso={caso}
          togglePreview={this._togglePreview}
        />
      ));
    } else {
      return null;
    }
  }

  _renderCasosFinalizados() {
    let casosFinalizados = this.state.casosFinalizados;
    if (casosFinalizados.length) {
      return casosFinalizados.map((caso, index) => (
        <Caso
          key={"caso-" + index}
          datosCaso={caso}
          togglePreview={this._togglePreview}
        />
      ));
    } else {
      return null;
    }
  }

  _togglePreview(imgUrl, caso = null) {
    if (caso) {
      if (!caso.dermatologo) {
        const preview = {
          imgUrl: imgUrl,
          nombrePaciente: caso.nombrePaciente + " " + caso.apellidoPaciente,
          nombreDoctor:
            caso.datosUsuario.nombre + " " + caso.datosUsuario.apellido,
          rutPaciente: caso.rutPaciente,
          centro: caso.datosUsuario.centro.replace(/_/g, " "),
          fecha: moment(caso.historial[0].fecha).format("D/M/YYYY"),
          caso: caso
        };

        this.setState({
          preview: preview,
          showPreview: true
        });
      } else {
        this._toggleCasoDetail(caso);
      }
    } else {
      this.setState({
        preview: null,
        showPreview: false
      });
    }
  }

  _casoAssign(caso) {
    const dermatologo = encrypterEngine.decrypt(getUsuarioDermatologo());
    const self = this;

    self.setState({ loading: true });

    let d = new Date();

    const casoModified = {
      ...caso,
      dermatologo: dermatologo.nombreUsuario,
      estado: 2,
      historial: [
        ...caso.historial,
        {
          tipo: "proceso",
          texto: "Caso en proceso",
          fecha: d.toString()
        }
      ]
    };

    base
      .database()
      .ref("pacientes/" + caso.rutPaciente)
      .update(casoModified, error => {
        if (!error) {
          self._toggleCasoDetail(caso);
        } else {
          alert("Error al intentar asignarlo al caso");
        }
      });
  }

  _toggleCasoDetail(caso = null) {
    if (caso) {
      const showDetail = !this.state.showDetail;
      this.setState({
        datosCasoDetail: caso,
        showDetail: showDetail,
        preview: null,
        showPreview: false
      });
    } else {
      this.setState({
        datosCasoDetail: null,
        showDetail: false,
        preview: null,
        showPreview: false,
        loading: false
      });

      this._getFromDb();
    }
  }

  render() {
    return (
      <div className="global-container">
        <Cabecera getCasosWithFilter={this._getCasosWithFilter} />

        {this.state.datosCasoDetail && this.state.showDetail ? (
          <CasoDetail
            datosCaso={this.state.datosCasoDetail}
            toggleCasoDetail={this._toggleCasoDetail}
            nombreDermatologo={this.state.nombreDermatologo}
            nombreUsuarioDermatologo={this.state.nombreUsuarioDermatologo}
            emailDermatologo={this.state.emailDermatologo}
          />
        ) : this.state.loading && !this.state.showDetail ? (
          <Loading color="#009092" />
        ) : (
          <div className="casos">
            <ScrollArea horizontal={false} className="col-casos cola">
              <p className="titulo-col">en cola</p>
              {this.state.casosCola.length ? (
                this._renderCasosCola()
              ) : (
                <div className="casos-mensaje">
                  <p>No hay casos en cola</p>
                </div>
              )}
            </ScrollArea>

            <ScrollArea horizontal={false} className="col-casos proceso">
              <p className="titulo-col">en proceso</p>

              {this.state.casosProceso.length ? (
                this._renderCasosProceso()
              ) : (
                <div className="casos-mensaje">
                  <p>No hay casos en proceso</p>
                </div>
              )}
            </ScrollArea>

            <ScrollArea horizontal={false} className="col-casos finalizado">
              <p className="titulo-col">finalizados</p>
              {this.state.casosFinalizados.length ? (
                this._renderCasosFinalizados()
              ) : (
                <div className="casos-mensaje">
                  <p>No hay casos finalizados</p>
                </div>
              )}
            </ScrollArea>
          </div>
        )}

        {this.state.preview && this.state.showPreview ? (
          <div className="caso-preview-container">
            <CasoPreview
              preview={this.state.preview}
              casoAssign={this._casoAssign}
              togglePreview={this._togglePreview}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ListadoCasos;
