// Libraries
import React from 'react';

class Loading extends React.Component {
    
    constructor(){
        super();

        this.state = {
        };
    }
    
    render() {
        return ( 
            <div className="multi-spinner-container">
                <div className="multi-spinner" style={this.props.color ? {borderTopColor: this.props.color} : null}>
                    <div className="multi-spinner" style={this.props.color ? {borderTopColor: this.props.color} : null}>
                        <div className="multi-spinner" style={this.props.color ? {borderTopColor: this.props.color} : null}>
                        <div className="multi-spinner" style={this.props.color ? {borderTopColor: this.props.color} : null}>
                                <div className="multi-spinner" style={this.props.color ? {borderTopColor: this.props.color} : null}>
                                    <div className="multi-spinner" style={this.props.color ? {borderTopColor: this.props.color} : null}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Loading;