export const mainPass = "geCY2M9DH5NXERd6fgrLGcjfqFo7vVu3";

export const getUsuarioCentro = () => {
  if (window.localStorage.getItem("usuarioCentro") !== null) {
    return window.localStorage.getItem("usuarioCentro");
  } else {
    return false;
  }
};

export const getUsuarioDermatologo = () => {
  if (window.localStorage.getItem("usuarioDermatologo") !== null) {
    return window.localStorage.getItem("usuarioDermatologo");
  } else {
    return false;
  }
};

export const getMes = numMes => {
  let arrMeses = [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic"
  ];
  return arrMeses[numMes - 1];
};
